const images = {
  backgroundNumber: require('./background-number.svg').default,
  iconCusd: require('./icon-cusd.png'),
  iconCusd2: require('./icon-cusd-2.png'),

  logoDojo: require('./logo-dojo.png'),
  logoArken: require('./logo-arken.png'),
  logoBaryon: require('./logo-baryon.png'),
  logoHyperlane: require('./logo-hyperlane.png'),
  logoSocket: require('./logo-socket.png'),
  logoOneid: require('./logo-oneid.png'),
  logoStarship: require('./logo-starship.png'),
  logoDagora: require('./logo-dagora.png'),

  bank: require('./bank.png'),
  transfer: require('./transfer.png'),
  radarCusd: require('./radar-cusd.png'),
  globalCusd: require('./global-cusd.png'),
  bgCusd: require('./bg-cusd.png'),
}

export default images
