import images from 'assets/images'
import {
  arrContentSection2,
  arrFaq,
  arrProject,
  arrStep,
} from 'common/constant'
import 'firebase/analytics'
import Button from 'components/Button'
import useResizeWidth from 'hooks/useResizeWidth'
import Footer from 'layout/Footer'
import Header from 'layout/Header'
import { useEffect, useRef, useState } from 'react'
import { Fade, Zoom } from 'react-awesome-reveal'

function App() {
  const questionRef = useRef(null)

  const { widthResize } = useResizeWidth()

  const [questionExpanse, setQuestionExpanse] = useState('question1')

  const handleExpanse = (id) => () => {
    setQuestionExpanse(id)
  }

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }, [])

  return (
    <div className='App'>
      <Header />
      <div className='content-wrapper'>
        <div className='section-banner'>
          <div className='icon-logo-banner-mobile'>
            <Zoom triggerOnce>
              <img src={images.bgCusd} alt='' className='bg-image' />
            </Zoom>
          </div>

          <Fade triggerOnce cascade direction='up'>
            <h1 className='banner-text banner-text--title'>
              The next <span>liquidity solution</span> for{' '}
              <span>cross-chain economy</span>
            </h1>

            <div className='banner-text banner-text--description'>
              CUSD is a fully-backed US Dollar stablecoin for seamless and
              borderless transactions. Regulatory-compliant and trusted, CUSD
              maintains a constant 1:1 exchange with the US Dollar, offering a
              reliable digital currency solution.
            </div>

            <div className='banner-button'>
              <Button url='https://stably.io/cusd/' isBlank>
                get cusd
              </Button>
            </div>
          </Fade>

          <div className='icon-logo-banner'>
            <Zoom triggerOnce delay={1000}>
              <img src={images.bgCusd} alt='' className='bg-image' />
            </Zoom>
          </div>
        </div>
        <div className='section-wrapper'>
          <div className='section-info'>
            {arrContentSection2.map((item, i) => {
              return (
                <div
                  className={`info-box ${
                    item.isReserve && 'info-box--reverse'
                  }`}
                  key={i}
                >
                  {item.content.map((itemChild, index) => {
                    return (
                      <div
                        className={`box-item ${
                          itemChild.isBig && 'box-item--big'
                        } ${
                          itemChild.isColorReserve && 'box-item--reserve-color'
                        }`}
                        key={index}
                      >
                        <Fade triggerOnce>
                          <div className='box-item--img'>
                            <img
                              src={itemChild.image}
                              alt=''
                              className='icon-content'
                            />
                          </div>
                        </Fade>

                        <div className='box-item--text'>
                          <Fade triggerOnce cascade direction='up'>
                            <h5 className='text-title'>{itemChild.title}</h5>

                            <div
                              className='text-description text-sm'
                              dangerouslySetInnerHTML={{
                                __html: itemChild.content,
                              }}
                            ></div>
                          </Fade>
                        </div>
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>

        <div className='section-wrapper section-wrapper--it-work'>
          <div className='section-it-work'>
            <Zoom triggerOnce>
              <div className='img-it-work'>
                <img
                  src={images.iconCusd2}
                  alt=''
                  className='img-token img-token--token-2'
                />
                <img src={images.iconCusd} alt='' className='img-token' />
              </div>
            </Zoom>

            <div className='content'>
              <Fade triggerOnce direction='left'>
                <div>
                  <h1 className='content-title'>How It Works</h1>

                  <h6 className='content-title'>To issue or redeem CUSD:</h6>
                </div>

                <div className='content-text'>
                  {arrStep.map((item, i) => {
                    const isLastItem = i === 2

                    return (
                      <div
                        className={`item-text ${
                          isLastItem && 'item-text--last-item'
                        }`}
                        key={i}
                      >
                        <div className='item-text--index'>
                          <h6>{item.index}</h6>
                        </div>

                        <div
                          className='item-text--content'
                          dangerouslySetInnerHTML={{ __html: item.content }}
                        ></div>
                      </div>
                    )
                  })}
                </div>

                <div className='line'></div>

                <div className='text-description'>
                  Note: CUSD is not available for US residents
                </div>

                <div className='button-it-work'>
                  <Button
                    className='button-custom'
                    background='background-black'
                    url='https://support.stably.io/en/collections/3947756-buy-sell-swap'
                    isBlank
                  >
                    Learn more
                  </Button>
                </div>
              </Fade>
            </div>
          </div>
        </div>

        <div className='section-wrapper section-wrapper--hidden'>
          <div className='section-trusted'>
            <Fade triggerOnce cascade direction='up'>
              <h1 className='trusted-title'>Trusted by</h1>
            </Fade>

            <div className='trusted-project'>
              <Fade triggerOnce cascade direction='up' duration={500}>
                {arrProject.map((item, i) => {
                  return (
                    <a
                      href={item.link}
                      target='_blank'
                      key={i}
                      rel='noreferrer'
                    >
                      <div className='item-project'>
                        <img src={item.image} className='icon-project' alt='' />

                        <div className='item-project--name'>{item.name}</div>
                      </div>
                    </a>
                  )
                })}
              </Fade>
            </div>
          </div>
        </div>

        <div className='section-wrapper section-wrapper--faq section-wrapper--hidden'>
          <span className='icon-web_logo_cusd icon-img'></span>

          <div className='section-faq'>
            <h1 className='faq-title'>FAQs</h1>

            <div className='faq-question'>
              {arrFaq.map((item, i) => {
                const questionActive = item.value === questionExpanse

                return (
                  <div
                    className='item-faq'
                    style={{
                      '--height-question':
                        widthResize < 768
                          ? item.heightQuestionMobile
                          : widthResize < 1024
                          ? item.heightQuestionTablet
                          : item.heightQuestion,
                    }}
                    key={i}
                  >
                    <div
                      className={`item-faq-wrapper ${
                        questionActive && 'item-faq-wrapper--expanse'
                      }`}
                    >
                      <div
                        className='item-faq--question'
                        onClick={handleExpanse(item.value)}
                      >
                        {item.question}
                        <span
                          className={`icon-web_arrow_down icon-down ${
                            questionActive && 'icon-down--active'
                          }`}
                        ></span>
                      </div>

                      <div
                        className='item-faq--answer text-sm'
                        dangerouslySetInnerHTML={{ __html: item.answer }}
                        ref={questionRef}
                      ></div>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

        <div className='section-wrapper section-wrapper--description'>
          <Fade
            triggerOnce
            cascade
            direction='up'
            className='section-description'
          >
            <h3 className='description-title'>
              Experience the future of cross-chain liquidity today
            </h3>

            <div className='button-description'>
              <Button
                background='background-black'
                url='https://stably.io/cusd/'
                isBlank
              >
                GET STARTED WITH CUSD
              </Button>
            </div>
          </Fade>
        </div>
      </div>
      <Footer />
    </div>
  )
}

export default App
