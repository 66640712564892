import React from 'react'
import { arrFooter } from 'common/constant'

import './Footer.scss'
import moment from 'moment'

function Footer() {
  const date = moment().format('yyyy')

  return (
    <div className='footer-wrapper'>
      <div className='footer-container'>
        <div>
          <div className='logo'>
            <span className='icon-web_logo_cusd icon-logo'></span>
            CUSD
          </div>
        </div>

        <div className='box-footer'>
          {arrFooter.map((item, i) => {
            return (
              <div className='item-menu' key={i}>
                <h6 className='item-menu--title'>{item.title}</h6>

                <div className='item-menu--menus'>
                  {item.menu.map((itemChild, index) => {
                    return (
                      <div
                        className={`menu ${
                          itemChild.isComing && 'menu--coming'
                        }`}
                        key={index}
                      >
                        <a
                          rel='noopener noreferrer'
                          href={itemChild.link}
                          target='_blank'
                        >
                          {itemChild.name}{' '}
                          {itemChild.isComing && (
                            <span className='coming-soon'>Coming Soon</span>
                          )}
                        </a>
                      </div>
                    )
                  })}
                </div>
              </div>
            )
          })}
        </div>
      </div>

      <div className='footer-description'>
        <div className='footer-description--title text-xsm'>
          IMPORTANT DISCLOSURES:
        </div>

        <div className='footer-description--description'>
          Thank you for visiting the landing page information regarding CUSD, a
          stablecoin issued by Stably. Please review the following terms
          carefully before proceeding:
        </div>

        <div className='footer-description--content-box'>
          <div className='item-text'>
            <span>1. Issuer Disclaimer:</span> CUSD is issued by Stably Trading
            LLC (“Stably”), a FinCEN-registered money service business and
            wholly-owned subsidiary of Stably Corporation. We disclaim any and
            all liabilities associated with CUSD arising from its issuance by
            Stably.
          </div>

          <div className='item-text'>
            <span>2. Non-Responsibility Statement:</span> We shall not be held
            responsible for any claims made by end users, including but not
            limited to:
            <div className='list-text'>
              <div className='list-text--item'>
                <div className='dot'></div>
                Losses or damages resulting from technical issues or
                interruptions in the CUSD service
              </div>
              <div className='list-text--item'>
                <div className='dot'></div>
                Changes in the value of CUSD or any market-related fluctuations
              </div>
              <div className='list-text--item'>
                <div className='dot'></div>
                Unauthorized transactions or breaches of security
              </div>
              <div className='list-text--item'>
                <div className='dot'></div>
                Inherent risks associated with digital assets
              </div>
            </div>
          </div>

          <div className='item-text'>
            We shall not be held liable for any direct, indirect, incidental,
            special, or consequential damages arising out of or in any way
            connected to the use or reliance on the information provided on this
            landing page.
          </div>

          <div className='item-text'>
            <span>3. Third-Party Terms and Conditions:</span> Users are required
            to comply with{' '}
            <a
              href='https://stably.io/terms-of-service/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Stably's Terms of Service
            </a>{' '}
            when using or engaging with CUSD. We are not responsible for{' '}
            <a
              href='https://stably.io/terms-of-service/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Stably's Terms of Service
            </a>{' '}
            and have no control over them.
          </div>

          <div className='item-text'>
            <span>4. Informational Purpose Only:</span> The content on this
            landing page is for informational purposes only and does not
            constitute financial, investment, or legal advice. We do not
            endorse, guarantee, or make any representations regarding the
            accuracy, reliability, or completeness of the information provided.
          </div>

          <div className='item-text'>
            <span>5. Risks Acknowledgment:</span> Users should be aware that the
            use of cryptocurrencies, including stablecoins like CUSD, involves
            inherent risks. We strongly advise users to conduct their own
            research and to seek professional advice before engaging with CUSD.
            For additional information, please refer to{' '}
            <a
              href='http://stably.io/disclaimer'
              target='_blank'
              rel='noopener noreferrer'
            >
              Stably’s Risk Disclaimer
            </a>
            .
          </div>

          <div className='item-text'>
            <span>6. Intellectual Property:</span> All content, including but
            not limited to text, graphics, logos, and images, on this landing
            page is our property and may not be used or reproduced without our
            prior written permission.
          </div>

          <div className='item-text'>
            <span>7. Updates and Changes:</span> We reserve the right to update,
            modify, or remove content from this landing page without providing
            prior notice.
          </div>

          <div className='item-text'>
            By accessing or using this landing page, you acknowledge that you
            have read, understood, and agreed to the terms outlined in this
            Legal Notice. Your continued use of the site constitutes acceptance
            of these terms. For information regarding{' '}
            <a
              href='https://stably.io/terms-of-service/'
              target='_blank'
              rel='noopener noreferrer'
            >
              Stably's Terms of Service
            </a>
            , please refer to their official website. If you do not agree to
            these terms, please refrain from using this landing page.
          </div>
        </div>

        <div className='footer-description--footer'>
          @ {date} CUSD. All Rights Reserved
        </div>
      </div>
    </div>
  )
}

export default Footer
