import React from 'react'
import Button from 'components/Button'

import './Header.scss'

function Header() {
  return (
    <div className='header-wrapper'>
      <div className='header-container'>
        <div className='header-logo'>
          <span className='icon-web_logo_cusd icon-logo'></span>
          CUSD
        </div>

        <div className='header-right'>
          <div className='box-text'>
            <a
              href='https://twitter.com/CUSD_xyz'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='icon-web_logo_twitter icon-social'></span>
            </a>

            <a
              href='http://blog.cusd.xyz/'
              target='_blank'
              rel='noopener noreferrer'
            >
              <span className='icon-web_logo_amber icon-social'></span>
            </a>
          </div>

          <div className='vertical-line'></div>

          <Button
            type='small'
            className='button-header'
            url='https://stably.io/cusd/'
            isBlank
          >
            get cusd
          </Button>
        </div>
      </div>
    </div>
  )
}

export default Header
