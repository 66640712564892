const { default: images } = require('assets/images')

const arrContentSection2 = [
  {
    content: [
      {
        title: 'Fully Collateralized & Attested',
        image: images.bank,
        content:
          'CUSD is fully collateralized by a reserve of highly liquid US Dollar assets such as bank deposits, money market instruments, and USDC. The CUSD collateral reserve and circulating token supply are transparently verified by an independent third party with periodic attestations.',
        isBig: true,
      },
      {
        title: 'Near-Zero Cost',
        image: images.transfer,
        content:
          'Transfer and convert CUSD with minimal cost compared to traditional banks. Transactions between digital wallets are also settled within seconds. <br/> <br/> <span>CUSD is built upon the Viction network’s VRC25 token standard, allowing for gas-free transactions.</span>',
        isColorReserve: true,
      },
    ],
  },
  {
    isReserve: true,
    content: [
      {
        title: 'Cross-Chain Interactions',
        image: images.radarCusd,
        content:
          'CUSD was launched initially on Viction and will be expanded to more chains in the near future to provide users with limitless interactions.',
      },
      {
        title: 'Worldwide Accessibility',
        image: images.globalCusd,
        content:
          'Individuals and businesses can acquire CUSD through multiple payment methods, including fiat and stablecoins. Anyone with an internet connection and a digital wallet can easily obtain and use CUSD.',
        isBig: true,
        isColorReserve: true,
      },
    ],
  },
]

const arrStep = [
  {
    index: 1,
    content: `Navigate to <a href='http://ramp.stably.io/' target='_blank' class='content-link'>ramp.stably.io</a> and open an account`,
  },
  {
    index: 2,
    content: `Follow the KYC onboarding process`,
  },
  {
    index: 3,
    content: `Once the account is set up, you will be able to buy and sell CUSD with fiat payments or swap CUSD with other stablecoins`,
  },
]

const arrProject = [
  {
    image: images.logoDojo,
    name: 'Dojo',
    link: 'https://app.dojoswap.xyz/',
  },
  {
    image: images.logoBaryon,
    name: 'Baryon',
    link: 'https://www.baryon.network/',
  },
  {
    image: images.logoArken,
    name: 'Arken',
    link: 'https://arken.finance/',
  },
  {
    image: images.logoHyperlane,
    name: 'Hyperlane',
    link: 'https://www.hyperlane.xyz/',
  },
  {
    image: images.logoSocket,
    name: 'Socket',
    link: 'https://www.socket.tech/',
  },
  {
    image: images.logoOneid,
    name: 'OneID',
    link: 'https://www.oneid.xyz/',
  },
  {
    image: images.logoStarship,
    name: 'Starship',
    link: 'https://starship.network/',
  },
  {
    image: images.logoDagora,
    name: 'Dagora',
    link: 'https://dagora.xyz/',
  },
]

const arrFaq = [
  {
    question: 'How can I get CUSD?',
    answer: `Acquiring CUSD can be done in several ways. You can get direct access to CUSD through <a href='http://stably.io/cusd' target='_blank'>Stably Ramp</a> with your preferred fiat or stablecoin payment methods. You can also obtain CUSD through the secondary market via over-the-counter (OTC) desks and decentralized exchanges (DEX). Currently, there are a few OTC desks (e.g., <a href='http://stably.io/otc' target='_blank'>Stably OTC</a>) and DEXs on Viction (e.g., <a href='http://dojo.market' target='_blank'>DojoSwap</a>, <a href='https://www.baryon.network/' target='_blank'>Baryon Network</a>) that offer liquidity for CUSD, providing users with several convenient options to acquire CUSD. However, it's crucial for you to conduct thorough prior research and verify the reliability as well as credibility of the exchanges you intend to use.`,
    value: 'question1',
    heightQuestion: '25rem',
    heightQuestionTabket: '35rem',
    heightQuestionMobile: '40rem',
  },
  {
    question:
      'How can I know that CUSD will always be redeemable 1:1 to US dollars?',
    answer: `CUSD is a digital stablecoin backed 100% by a reserve of highly liquid collateral assets. The reserve is held in bank deposits, money market instruments, and USDC by a designated trustee for the benefit of verified CUSD token holders. Periodic attestations will be conducted by a third-party attestor to verify the circulating token supply and collateral reserve of CUSD. Monthly statements of the collateral accounts are also published by Stably. For more information, please visit Stably’s <a href='http://stably.io/cusd' target='_blank'>website</a>.`,
    value: 'question2',
    heightQuestion: '22rem',
    heightQuestionTablet: '25rem',
    heightQuestionMobile: '35rem',
  },
  {
    question: 'How can I convert CUSD back to fiat?',
    answer: `
    <a href='https://stably.io/stably-ramp/' target='_blank'>Stably Ramp</a> allows you to sell CUSD via fiat payment methods or swap CUSD for other stablecoins like USDC at a 1:1 conversion rate and minimal fees.`,
    value: 'question3',
    heightQuestion: '15rem',
    heightQuestionTablet: '15rem',
    heightQuestionMobile: '20rem',
  },
  {
    question: 'What can I do with my CUSD?',
    answer: `
      With CUSD, you can start your journey into Web3.0 in many different ways:<br/>
      - <span>Buy, sell, and swap</span> CUSD via <a href='http://stably.io/cusd' target='_blank'>Stably Ramp</a> or OTC desks, including <a href='http://stably.io/otc' target='_blank'>Stably OTC</a>.<br/>
      - <span>Trade</span> CUSD for other digital assets via our DEX partners, including <a href='http://dojo.market' target='_blank'>DojoSwap</a>, <a href='https://www.baryon.network/' target='_blank'>Baryon Network</a>.<br/>
      - <span>Send and receive</span> CUSD on-chain to/from your friends and family at minimal cost.<br/> 
      - <span>Pay</span> CUSD to purchase NFTs via partners in our network, including <a href='https://dagora.xyz/' target='_blank'>Dagora</a>. 
      `,
    value: 'question4',
    heightQuestion: '23rem',
    heightQuestionMobile: '35rem',
  },
]

const arrFooter = [
  {
    title: 'CUSD',
    menu: [
      {
        name: 'About CUSD',
        link:
          'https://blog.cusd.xyz/introducing-cusd-a-fresh-update-empowering-the-cross-chain-economy-G5v22Brn',
      },
      {
        name: 'Documentation',
        link: 'https://support.stably.io/en/articles/8534340-what-is-cusd-cusd',
      },
      {
        name: 'Brand Assets',
        link:
          'https://drive.google.com/drive/u/0/folders/17h1PXOQSXRvX4TaHE9sFYZ9i1qlhoSP9',
      },
    ],
  },
  {
    title: 'Contact us',
    menu: [
      {
        name: 'X (Twitter)',
        link: 'https://twitter.com/CUSD_xyz',
      },
      {
        name: 'Blog',
        link: 'http://blog.cusd.xyz/',
      },
    ],
  },
  {
    title: 'Networks',
    menu: [
      {
        name: 'Viction',
        link: 'https://viction.xyz/',
      },
    ],
  },
  {
    title: 'Legal',
    menu: [
      {
        name: 'Privacy Policy',
        link: 'https://stably.io/privacy-policy/',
      },
      {
        name: 'Terms of Service',
        link: 'https://stably.io/terms-of-service/',
      },
    ],
  },
]

export { arrContentSection2, arrProject, arrStep, arrFaq, arrFooter }
