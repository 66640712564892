import React from 'react'

import './Button.scss'

function Button({
  children,
  className,
  type = 'default',
  background,
  url,
  isBlank = false,
}) {
  if (url) {
    return (
      <a href={url} target={isBlank && '_blank'}>
        <button
          className={`button-wrapper ${className} button-wrapper--${type} button-wrapper--${background}`}
        >
          {children}
        </button>
      </a>
    )
  }

  return (
    <button
      className={`button-wrapper ${className} button-wrapper--${type} button-wrapper--${background}`}
    >
      {children}
    </button>
  )
}

export default Button
